function preloadImage(url) {
    let img = new Image();
    img.src = url;
}
export default function (initialImageUrl, initialImageTitle) {
    return {
        fullScreen: false,
        hideGallery: false,
        activeImage: {
            url: initialImageUrl || '',
            title: initialImageTitle || '',
        },
        setActiveImage(url, title) {
            if (url !== this.activeImage.url) {
                this.hideGallery = true;
                this.$nextTick(() => {
                    window.setTimeout(() => {
                        this.activeImage.url = url;
                        this.activeImage.title = title || url;
                        window.setTimeout(() => {
                            this.$nextTick(() => {
                                this.hideGallery = false;
                            });
                        }, 100);
                    }, 100);
                });
            }
        },
        thumbnailClick(event) {
            event.preventDefault();
            if (event.target && event.target instanceof HTMLElement) {
                const sourceDataEl = event.target.closest('li');
                if (sourceDataEl && sourceDataEl instanceof HTMLElement) {
                    const sourceDataset = sourceDataEl.dataset;
                    this.setActiveImage(sourceDataset.url || '', sourceDataset.title || '');
                }
            }
        },
        shiftActiveImage(offset) {
            let index = this.images.findIndex((item) => item.url === this.activeImage.url);
            if (index !== -1) {
                index = index + offset;
                if (index < 0) {
                    index += this.images.length;
                }
                else if (index >= this.images.length) {
                    index = 0;
                }
                const image = this.images[index];
                this.setActiveImage(image.url, image.title);
            }
        },
        nextImage() {
            this.shiftActiveImage(1);
        },
        previousImage() {
            this.shiftActiveImage(-1);
        },
        get images() {
            const urls = [];
            this.$root.querySelectorAll('[data-purpose="thumbnail"][data-visible="true"]').forEach((el) => {
                if (el instanceof HTMLElement && el.dataset.url) {
                    urls.push({
                        url: el.dataset.url,
                        thumbnail: el.dataset.thumbnail || el.dataset.url,
                        title: el.dataset.title || el.dataset.url,
                    });
                }
            });
            return urls;
        },
        init() {
            // Preload all thumbnails (keep existing code)
            this.$root.querySelectorAll('[data-purpose="thumbnail"]').forEach((el) => {
                if (el instanceof HTMLElement && (el.dataset.thumbnail || el.dataset.url)) {
                    preloadImage((el.dataset.thumbnail || el.dataset.url));
                }
            });
            // Add event listener for variant changes with proper type casting
            window.addEventListener('variant-image-change', ((event) => {
                // Cast the event to our custom event type
                const customEvent = event;
                if (customEvent.detail && customEvent.detail.url) {
                    this.setActiveImage(customEvent.detail.url, customEvent.detail.title || '');
                }
            }));
        }
    };
}
