export default (function (el, { modifiers, expression }, { evaluate, cleanup }) {
    const angleThreshold = 20;
    if (!expression) {
        return;
    }
    const intendedDirection = modifiers[0] || null;
    if (!['left', 'right', 'up', 'down', null].includes(intendedDirection)) {
        return;
    }
    const pageWidth = window.innerWidth || document.body.clientWidth;
    const minDistance = Math.max(1, Math.floor(0.01 * (pageWidth)));
    const state = {
        coordinates: {
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
        },
        changes: {
            x: 0,
            y: 0,
        },
    };
    const handler = (ev) => {
        if (ev.type === 'touchstart') {
            ({ screenX: state.coordinates.startX, screenY: state.coordinates.startY } = ev.changedTouches[0]);
        }
        else if (ev.type === 'touchend') {
            ({ screenX: state.coordinates.endX, screenY: state.coordinates.endY } = ev.changedTouches[0]);
            state.changes.x = state.coordinates.endX - state.coordinates.startX;
            state.changes.y = state.coordinates.endY - state.coordinates.startY;
            const angle = Math.atan(Math.abs(state.changes.x / state.changes.y)) / Math.PI * 180;
            // ensure the finger moved more than 1% of the screen
            if (Math.abs(state.changes.x) > minDistance || Math.abs(state.changes.y) > minDistance) {
                if (!intendedDirection) {
                    evaluate(expression);
                }
                else {
                    let direction;
                    if (angle <= angleThreshold) {
                        if (state.changes.y < 0) {
                            direction = 'up';
                        }
                        else {
                            direction = 'down';
                        }
                    }
                    else if (angle >= (90 - angleThreshold)) {
                        if (state.changes.x < 0) {
                            direction = 'left';
                        }
                        else {
                            direction = 'right';
                        }
                    }
                    if (direction === intendedDirection) {
                        evaluate(expression);
                    }
                }
            }
        }
    };
    el.addEventListener('touchstart', handler);
    el.addEventListener('touchend', handler);
    cleanup(() => {
        el.removeEventListener('touchstart', handler);
        el.removeEventListener('touchend', handler);
    });
});
