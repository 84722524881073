export default function (initialActiveVariantSku) {
    const variantSelectorDataEl = document.querySelector('#variant-selector-data');
    const variantDataEl = document.querySelector('#variant-data');
    if (variantDataEl && variantDataEl.textContent) {
        let source = JSON.parse(variantDataEl.textContent);
        window.variants = {};
        for (const [sku, variant] of Object.entries(source)) {
            variant.pricing.dealer_price_ex_tax = Number(variant.pricing.dealer_price_ex_tax);
            variant.pricing.rrp_ex_tax = Number(variant.pricing.rrp_ex_tax);
            variant.pricing.rrp_inc_tax = Number(variant.pricing.rrp_inc_tax);
            variant.pricing.go_price_ex_tax = Number(variant.pricing.go_price_ex_tax);
            variant.pricing.go_price_inc_tax = Number(variant.pricing.go_price_inc_tax);
            variant.pricing.margin = Number(variant.pricing.margin);
            variant.pricing.markup = Number(variant.pricing.markup);
            variant.thumbnail = '';
            const thumbnailEl = document.querySelector(`[data-purpose="thumbnail"][data-sku="${sku}"] img`);
            if (thumbnailEl) {
                variant.thumbnail = thumbnailEl.getAttribute('src') || '';
            }
            window.variants[sku] = variant;
        }
    }
    if (variantSelectorDataEl && variantSelectorDataEl.textContent) {
        window.variantSelectors = JSON.parse(variantSelectorDataEl.textContent);
        Object.entries(window.variantSelectors.selectors).forEach(([index, item]) => {
            item.optionsByHash = {};
            item.options.forEach(option => {
                item.optionsByHash[option.hash] = option;
            });
        });
    }
    const queryString = {};
    for (const [key, val] of (new URL(window.document.location.toString())).searchParams) {
        if (key !== 'variant') {
            queryString[key] = val;
        }
    }
    initialActiveVariantSku = String(initialActiveVariantSku) || '';
    // Function to build comprehensive compatibility matrix for all levels
    function buildCompatibilityMatrix() {
        // Create a compatibility lookup for quick reference
        window.compatibilityMatrix = {
            levels: {} // Maps level -> option hash -> compatible option hashes for other levels
        };
        // Initialize the structure for each level
        const selectorCount = window.variantSelectors.selectors.length;
        for (let level = 0; level < selectorCount; level++) {
            window.compatibilityMatrix.levels[level] = {};
            // For each option in this level
            window.variantSelectors.selectors[level].options.forEach(option => {
                window.compatibilityMatrix.levels[level][option.hash] = {};
                // Create empty arrays for all other levels
                for (let otherLevel = 0; otherLevel < selectorCount; otherLevel++) {
                    if (otherLevel !== level) {
                        window.compatibilityMatrix.levels[level][option.hash][otherLevel] = []; // Initialize as empty array
                    }
                }
            });
        }
        // Fill in compatibility data based on available variants
        for (const [sku, variant] of Object.entries(window.variants)) {
            // For each level
            for (let level = 0; level < selectorCount; level++) {
                const thisLevelHash = variant.selectors[level + 1];
                if (!thisLevelHash)
                    continue;
                // For each other level
                for (let otherLevel = 0; otherLevel < selectorCount; otherLevel++) {
                    if (otherLevel === level)
                        continue;
                    const otherLevelHash = variant.selectors[otherLevel + 1];
                    if (!otherLevelHash)
                        continue;
                    // Add the other level's hash to this level's compatibility list if not already there
                    const compatList = window.compatibilityMatrix.levels[level][thisLevelHash][otherLevel];
                    if (compatList && !compatList.includes(otherLevelHash)) {
                        compatList.push(otherLevelHash);
                    }
                }
            }
        }
        // Debug output
        console.log('Comprehensive Compatibility Matrix:', window.compatibilityMatrix);
    }
    // Build the compatibility matrix after loading variants
    buildCompatibilityMatrix();
    // Initialize these variables before calling enhanceOptionsWithVariantData
    let initialSelectedOption1Hash = '', initialSelectedOption2Hash = '', initialSelectedOption3Hash = '';
    if (window.variants[initialActiveVariantSku]) {
        initialSelectedOption1Hash = window.variants[initialActiveVariantSku].selectors[1] || '';
        initialSelectedOption2Hash = window.variants[initialActiveVariantSku].selectors[2] || '';
        initialSelectedOption3Hash = window.variants[initialActiveVariantSku].selectors[3] || '';
    }
    let initialSelectedOption1 = { value: '', hash: '', index: 99999 }, initialSelectedOption2 = { value: '', hash: '', index: 99999 }, initialSelectedOption3 = { value: '', hash: '', index: 99999 };
    window.variantSelectors.selectors.forEach((level, levelIndex) => {
        level.options.forEach((option, optionIndex) => {
            option.index = optionIndex;
            if (levelIndex === 0) {
                if (initialSelectedOption1Hash) {
                    if (option.hash === initialSelectedOption1Hash) {
                        initialSelectedOption1 = option;
                    }
                }
                else if (optionIndex === 0) {
                    // Default to first option if none selected
                    initialSelectedOption1 = option;
                }
            }
            else if (levelIndex === 1) {
                if (initialSelectedOption2Hash) {
                    if (option.hash === initialSelectedOption2Hash) {
                        initialSelectedOption2 = option;
                    }
                }
                else if (optionIndex === 0) {
                    // Default to first option if none selected
                    initialSelectedOption2 = option;
                }
            }
            else if (levelIndex === 2) {
                if (initialSelectedOption3Hash) {
                    if (option.hash === initialSelectedOption3Hash) {
                        initialSelectedOption3 = option;
                    }
                }
                else if (optionIndex === 0) {
                    // Default to first option if none selected
                    initialSelectedOption3 = option;
                }
            }
        });
    });
    // Improved determine if option is available function
    function determineIfOptionIsAvailable(level, option) {
        const selectedOptions = [initialSelectedOption1, initialSelectedOption2, initialSelectedOption3];
        // Check if we have the compatibility data for this option
        if (!window.compatibilityMatrix.levels[level] ||
            !window.compatibilityMatrix.levels[level][option.hash]) {
            console.warn(`No compatibility data for level ${level}, option ${option.value}`);
            return false;
        }
        // For each other level, check if current selection is compatible
        for (let otherLevel = 0; otherLevel < selectedOptions.length; otherLevel++) {
            if (otherLevel === level)
                continue; // Skip the current level
            const otherOption = selectedOptions[otherLevel];
            if (!otherOption || !otherOption.hash)
                continue; // Skip if no selection at this level
            // Get compatible options for the other level
            const compatibleOptions = window.compatibilityMatrix.levels[level][option.hash][otherLevel] || [];
            // If the other level's selected option is not compatible with this option, return false
            if (!compatibleOptions.includes(otherOption.hash)) {
                return false;
            }
        }
        // If we get here, the option is compatible with all current selections
        return true;
    }
    // Find a good representative variant for an option to display its image/price
    function findRepresentativeVariant(level, option) {
        // Gather all variants that use this option
        const matchingVariants = [];
        for (const [sku, variant] of Object.entries(window.variants)) {
            const optionLevel = level + 1; // Convert to 1-based index for selectors
            if (variant.selectors[optionLevel] === option.hash) {
                matchingVariants.push(variant);
            }
        }
        if (matchingVariants.length === 0) {
            return null;
        }
        // If there's only one matching variant, use that
        if (matchingVariants.length === 1) {
            return matchingVariants[0];
        }
        // If there are multiple options, try to find the one that's most representative
        const selectedOptions = [initialSelectedOption1, initialSelectedOption2, initialSelectedOption3];
        // Try to find a variant that matches current selections at other levels
        for (let otherLevel = 0; otherLevel < selectedOptions.length; otherLevel++) {
            if (otherLevel === level || !selectedOptions[otherLevel] || !selectedOptions[otherLevel].hash)
                continue;
            const matchWithSelectedOption = matchingVariants.find(variant => variant.selectors[(otherLevel + 1)] === selectedOptions[otherLevel].hash);
            if (matchWithSelectedOption) {
                return matchWithSelectedOption;
            }
        }
        // Otherwise just return the first matching variant
        return matchingVariants[0];
    }
    // Enhanced function to update option variants and availability
    function enhanceOptionsWithVariantData() {
        // First, add representativeVariant to each option
        window.variantSelectors.selectors.forEach((selector, level) => {
            selector.options.forEach(option => {
                option.representativeVariant = findRepresentativeVariant(level, option);
            });
        });
        // Then determine availability based on current selections
        window.variantSelectors.selectors.forEach((selector, level) => {
            selector.options.forEach(option => {
                option.isAvailable = determineIfOptionIsAvailable(level, option);
            });
        });
        // Log availability status for debugging
        const selectedOptions = [initialSelectedOption1, initialSelectedOption2, initialSelectedOption3];
        console.log('Current Selections:', selectedOptions.map(opt => (opt === null || opt === void 0 ? void 0 : opt.value) || 'none'));
        window.variantSelectors.selectors.forEach((selector, level) => {
            console.log(`Level ${level} (${selector.name}) Availability:`);
            selector.options.forEach(option => {
                console.log(`  ${option.value}: ${option.isAvailable ? 'Available' : 'Unavailable'}`);
            });
        });
    }
    // Call enhanceOptionsWithVariantData after all variables are initialized
    enhanceOptionsWithVariantData();
    return {
        variants: window.variants,
        variantSelectors: window.variantSelectors,
        showDealerPrice: false,
        activeVariantSku: initialActiveVariantSku,
        supersededBy: '',
        isBuyBoxSticky: false,
        compactView: false,
        quantity: 1,
        get activeVariant() {
            if (this.activeVariantSku) {
                return this.variants[this.activeVariantSku];
            }
        },
        setActiveVariant(sku) {
            this.activeVariantSku = sku;
            if (sku) {
                // Find the first thumbnail for this variant
                const firstThumbnail = document.querySelector(`[data-purpose="thumbnail"][data-sku="${sku}"]`);
                if (firstThumbnail instanceof HTMLElement) {
                    // Get the image URL and title
                    const imageUrl = firstThumbnail.dataset.url;
                    const imageTitle = firstThumbnail.dataset.title;
                    // Dispatch a custom event that both galleries can listen for
                    if (imageUrl) {
                        // Create a properly typed custom event
                        const variantChangeEvent = new CustomEvent('variant-image-change', {
                            detail: {
                                url: imageUrl,
                                title: imageTitle || ''
                            }
                        });
                        // Dispatch the event
                        window.dispatchEvent(variantChangeEvent);
                    }
                }
                // Rest of the method remains the same
                const path = window.location.href.split('?')[0];
                queryString['sku'] = sku.toLowerCase();
                const params = Object.entries(queryString).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join('&');
                window.history.replaceState({ htmx: true }, '', `${path}?${params}`);
            }
        },
        selectedOptions: {
            0: initialSelectedOption1,
            1: initialSelectedOption2,
            2: initialSelectedOption3,
        },
        getSkuForOption(level, option) {
            let sku = '';
            // Safety check - don't proceed if option is not available
            if (!option.isAvailable) {
                console.warn(`Attempted to select unavailable option: ${option.value} at level ${level}`);
                return '';
            }
            try {
                const selectedOptions = JSON.parse(JSON.stringify(this.selectedOptions));
                selectedOptions[level] = option;
                if (this.variantSelectors.selectors.length === 3) {
                    let result = this.variantSelectors.variants[selectedOptions[0].hash];
                    if (!result)
                        return '';
                    result = result[selectedOptions[1].hash];
                    if (!result)
                        return '';
                    sku = result[selectedOptions[2].hash];
                }
                else if (this.variantSelectors.selectors.length === 2) {
                    let result = this.variantSelectors.variants[selectedOptions[0].hash];
                    if (!result)
                        return '';
                    sku = result[selectedOptions[1].hash];
                }
                else if (this.variantSelectors.selectors.length === 1) {
                    sku = this.variantSelectors.variants[selectedOptions[0].hash];
                }
            }
            catch (error) {
                console.error('Error getting SKU for option:', error);
                return '';
            }
            return sku || '';
        },
        getVariantForOption(level, option) {
            const sku = this.getSkuForOption(level, option);
            if (sku) {
                return window.variants[sku];
            }
            else {
                return null;
            }
        },
        getOptionVariant(option) {
            // Return the pre-computed representative variant
            return option.representativeVariant || null;
        },
        isOptionAvailable(option) {
            // Return the pre-computed availability
            return option.isAvailable === true;
        },
        selectOption(level, option) {
            // Don't do anything if option is not available and not already selected
            if (!option.isAvailable && this.selectedOptions[level] !== option) {
                console.log(`Attempted to select unavailable option: ${option.value}`);
                // Find a compatible option at other levels to make this option available
                const otherLevels = [];
                for (let i = 0; i < window.variantSelectors.selectors.length; i++) {
                    if (i !== level)
                        otherLevels.push(i);
                }
                // For each other level, find a compatible option
                let foundCompatibleSelection = false;
                for (const otherLevel of otherLevels) {
                    // Get options for the other level that are compatible with this option
                    const compatibleOptions = window.compatibilityMatrix.levels[level][option.hash][otherLevel] || [];
                    if (compatibleOptions.length > 0) {
                        // Select the first compatible option at the other level
                        const compatibleOption = window.variantSelectors.selectors[otherLevel].options.find(opt => compatibleOptions.includes(opt.hash));
                        if (compatibleOption) {
                            console.log(`Selecting compatible option at level ${otherLevel}: ${compatibleOption.value}`);
                            this.selectedOptions[otherLevel] = compatibleOption;
                            foundCompatibleSelection = true;
                            break;
                        }
                    }
                }
                if (!foundCompatibleSelection) {
                    console.error(`Could not find compatible selections for option: ${option.value}`);
                    return; // Don't proceed with the selection
                }
            }
            if (this.selectedOptions[level] !== option) {
                this.selectedOptions[level] = option;
                const sku = this.getSkuForOption(level, option);
                if (sku) {
                    this.setActiveVariant(sku);
                }
                else {
                    // Try to find a valid variant with this option
                    let validVariant = null;
                    for (const [variantSku, variant] of Object.entries(this.variants)) {
                        if (variant.selectors[level + 1] === option.hash) {
                            validVariant = variant;
                            break;
                        }
                    }
                    if (validVariant) {
                        // Update other selections to match this variant
                        for (let i = 0; i < window.variantSelectors.selectors.length; i++) {
                            if (i !== level) {
                                const hash = validVariant.selectors[(i + 1)];
                                if (hash && window.variantSelectors.selectors[i].optionsByHash[hash]) {
                                    this.selectedOptions[i] = window.variantSelectors.selectors[i].optionsByHash[hash];
                                }
                            }
                        }
                        this.setActiveVariant(validVariant.sku);
                    }
                    else {
                        console.warn(`Could not find a valid variant with option: ${option.value}`);
                    }
                }
                // Update initialSelectedOption references to match current selection
                initialSelectedOption1 = this.selectedOptions[0];
                initialSelectedOption2 = this.selectedOptions[1];
                initialSelectedOption3 = this.selectedOptions[2];
                // Re-enhance options whenever selection changes
                enhanceOptionsWithVariantData();
                this.quantity = 1; // Reset quantity to 1 on selection change
            }
        },
        selectNextOption(level) {
            const currentIndex = this.selectedOptions[level].index;
            if (currentIndex !== undefined) {
                // Find the next available option
                let nextIndex = currentIndex + 1;
                const options = this.variantSelectors.selectors[level].options;
                // Wrap around to start if needed
                if (nextIndex >= options.length) {
                    nextIndex = 0;
                }
                // Select the next option
                this.selectOption(level, options[nextIndex]);
            }
        },
        selectPreviousOption(level) {
            const currentIndex = this.selectedOptions[level].index;
            if (currentIndex !== undefined) {
                // Find the previous available option
                let prevIndex = currentIndex - 1;
                const options = this.variantSelectors.selectors[level].options;
                // Wrap around to end if needed
                if (prevIndex < 0) {
                    prevIndex = options.length - 1;
                }
                // Select the previous option
                this.selectOption(level, options[prevIndex]);
            }
        },
        activeTab: 'overview',
        upsellDrawerOpen: false,
        addToCartSuccess: false,
        //@ts-ignore
        displayTradePrice: this.$persist(false).using(sessionStorage).as('displayProductTradePricing'),
        formatMoney(amount) {
            return amount.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        },
        incrementQuantity() {
            this.quantity = Math.min(99, this.quantity + 1);
        },
        decrementQuantity() {
            this.quantity = Math.max(1, this.quantity - 1);
        },
        init() {
            this.setActiveVariant(this.activeVariantSku);
            this.$el.addEventListener('item-added-to-cart', (event) => {
                // this.upsellDrawerOpen = true
            });
        },
    };
}
