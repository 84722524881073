export default function ({} = {}) {
    if (!!window.LiveChatWidget) {
        window.LiveChatWidget.call('destroy');
    }
    window.__lc = window.__lc || {};
    window.__lc.license = 3513921;
    window.__lc.asyncInit = true;
    (function (n, t, c) {
        function i(n) {
            return e._h ? e._h.apply(null, n) : e._q.push(n);
        }
        const e = {
            _q: [],
            h: null,
            _v: '2.0',
            on: function () {
                i(['on', c.call(arguments)]);
            },
            once: function () {
                i(['once', c.call(arguments)]);
            },
            off: function () {
                i(['off', c.call(arguments)]);
            },
            get: function () {
                if (!e._h)
                    throw new Error('[LiveChatWidget] You can\'t use getters before load.');
                return i(['get', c.call(arguments)]);
            },
            call: function () {
                i(['call', c.call(arguments)]);
            },
            init: function () {
                const n = t.createElement('script');
                n.async = !0, n.type = 'text/javascript', n.src = 'https://cdn.livechatinc.com/tracking.js', t.head.appendChild(n);
            }
        };
        !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e;
    })(window, document, [].slice);
    window.LiveChatWidget.call('set_customer_name', window.appConfig.user.name);
    window.LiveChatWidget.call('set_customer_email', window.appConfig.user.email);
    window.LiveChatWidget.init();
    // window.LiveChatWidget.call('hide')
}
