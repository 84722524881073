import Flickity from 'flickity';
// @ts-ignore
import wwwApi from '../../../api';
import 'flickity/css/flickity.css';
import 'flatpickr/dist/flatpickr.css';
import swipe from './swipe';
import initLiveChat from './liveChat';
import productPage from './products/productPage';
import gallery from './products/gallery';
import '../styles/index.css';
// @ts-ignore
window.wwwApi = new wwwApi.Client(window.appConfig);
window.Alpine.data('productPage', productPage);
window.Alpine.data('gallery', gallery);
window.Alpine.magic('clipboard', () => (subject) => {
    navigator.clipboard.writeText(subject);
});
window.Alpine.data('dropdown', () => {
    return {
        open: false,
        close() {
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
        }
    };
});
window.Alpine.data('interdynStaffBar', function () {
    return {
        //@ts-ignore
        show: this.$persist(true).using(sessionStorage).as('displayInterdynStaffBar'),
        closing: false,
        close() {
            this.closing = true;
            window.setTimeout(() => {
                this.show = false;
            }, 2500);
        },
        //@ts-ignore
        forceOpen: this.$persist(false).using(sessionStorage).as('forceOpenInterdynStaffBar'),
        alwaysOpen: true,
        toggle() {
            this.forceOpen = !this.forceOpen;
        },
        handleResize() {
            const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
            this.alwaysOpen = width >= 640;
        },
        init() {
            this.handleResize();
        },
        get isOpen() {
            return (this.alwaysOpen || this.forceOpen);
        }
    };
});
window.Alpine.data('carousel', function (persistenceKey = '') {
    return {
        //@ts-ignore
        active: this.$persist(0).using(sessionStorage).as(`carouselIndex-${persistenceKey}`),
        ready: false,
        flkty: null,
        init() {
            let autoPlay = false;
            if (!!this.$el.dataset.autoPlay) {
                autoPlay = parseInt(this.$el.dataset.autoPlay);
            }
            this.flkty = new Flickity(this.$refs.carousel, {
                wrapAround: true,
                autoPlay: autoPlay,
                initialIndex: this.active,
                prevNextButtons: !!this.$el.dataset.buttons,
                pageDots: !!this.$el.dataset.dots,
                groupCells: true,
            });
            this.flkty.on('change', (i) => this.active = i);
            this.ready = true;
            this.$nextTick(() => {
                this.flkty.resize();
            });
            window.addEventListener('resize', () => {
                this.flkty.resize();
            });
        }
    };
});
window.Alpine.data('messageBar', function () {
    const sessionKey = 'messageBarCurrentItem';
    const initialIndex = window.sessionStorage.getItem(sessionKey);
    return {
        messages: [],
        // @ts-ignore
        index: this.$persist(0).using(sessionStorage).as('messageBarCurrentIndex'),
        get message() {
            const message = this.messages[this.index];
            if (message) {
                return message;
            }
            return null;
        },
        show: true,
        init() {
            for (const el of this.$el.querySelectorAll('template')) {
                if (el.dataset && el.dataset instanceof DOMStringMap && el.dataset.message) {
                    const message = JSON.parse(JSON.stringify((el.dataset)));
                    this.messages.push(message);
                }
            }
            window.setInterval(() => {
                if (this.index >= (this.messages.length - 1)) {
                    this.index = 0;
                }
                else {
                    this.index += 1;
                }
            }, 9500);
        }
    };
});
window.Alpine.directive('swipe', swipe);
const auPhoneNumberMask = (input) => {
    return input.startsWith('04') ? '9999 999 999' : '99 9999 9999';
};
export { auPhoneNumberMask, initLiveChat, };
// @ts-ignore
if (!window.appConfig.alpineStarted) {
    window.Alpine.start();
    // @ts-ignore
    window.appConfig.alpineStarted = true;
}
